import { Button, Collapse, Input, Space, notification, Table, InputNumber, Form, Col, Row } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './SupplierAuthority.css';
import { fetchSupplierAuthorityConfigs, fetchVendorsForSupplierAuthority, updateVendorsForSupplierAuthority } from '../../redux/supplierAuthoritySlice';
import { sortVendors } from '../../helpers';

const { Panel } = Collapse;

const EditableCell = ({ value, onChange }) => (
    <InputNumber
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}
      step={1}
    />
  );

const SupplierAuthority = () => {
    const dispatch = useDispatch();
    const { vendors: initialVendors, configs: initialConfigs, status } = useSelector((state) => state.supplierAuthority);
    const [vendors, setVendors] = useState([]);
    const [configs, setConfigs] = useState([]);
    const [changedVendors, setChangedVendors] = useState([]);
    const [changedConfigs, setChangedConfigs] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const SUPPLIER_AUTHORITY_COLUMN_NAMES = [
        {
            title: 'Vendor',
            dataIndex: 'vendor_name',
            key: 'vendor_name'
        },
        {
            title: 'Refund (Big)',
            dataIndex: 'refund_big',
            key: 'refund_big'
        },
        {
            title: 'Stockout (Big)',
            dataIndex: 'stockout_big',
            key: 'stockout_big'
        },
        {
            title: 'Review (Big)',
            dataIndex: 'review_big',
            key: 'review_big'
        },
        {
            title: 'Refund (Small)',
            dataIndex: 'refund_small',
            key: 'refund_small'
        },
        {
            title: 'Stockout (Small)',
            dataIndex: 'stockout_small',
            key: 'stockout_small'
        },
        {
            title: 'Review (Small)',
            dataIndex: 'review_small',
            key: 'review_small'
        },
        {
            title: 'Relationship',
            dataIndex: 'relationship',
            key: 'relationship',
            render: (_, record, index) => (
            <EditableCell
                value={record.relationship}
                onChange={(value) => handleCellChange('relationship', value, index)}
            />
            ),
        },
        {
            title: 'Supplier Authority (Big)',
            dataIndex: 'supplier_authority_big',
            key: 'supplier_authority_big'
        },
        {
            title: 'Supplier Authority (Small)',
            dataIndex: 'supplier_authority_small',
            key: 'supplier_authority_small'
        },
    ];
    
    useEffect(() => {
        dispatch(fetchVendorsForSupplierAuthority());
        dispatch(fetchSupplierAuthorityConfigs());
    }, []);

    useEffect(() => {
        const sortedVendors = sortVendors(initialVendors);
        setVendors(sortedVendors);
    }, [initialVendors]);

    useEffect(() => {
        setConfigs(initialConfigs);
    }, [initialConfigs])


    const handleCellChange = (dataIndex, value, index) => {
        const newData = [...vendors];
        newData[index] = { ...newData[index], [dataIndex]: value };
    
        setVendors(newData);
    
        const updatedVendor = { _id: newData[index]._id, [dataIndex]: value };
    
        setChangedVendors(prevChangedVendors => {
            const existingVendorIndex = prevChangedVendors.findIndex(v => v._id === updatedVendor._id);
            if (existingVendorIndex > -1) {
                const updatedChangedVendors = [...prevChangedVendors];
                updatedChangedVendors[existingVendorIndex] = {
                    ...updatedChangedVendors[existingVendorIndex],
                    ...updatedVendor
                };
                return updatedChangedVendors;
            } else {
                return [...prevChangedVendors, updatedVendor];
            }
        });
    };
    

    const handleConfigChange = (property, value, index) => {
        const newData = [...configs];

        if (configs[index].key === 'review') {
            if (property === 'secondary_quantity_threshold' && value >= configs[index].quantity_threshold) {
                notification['error']({
                    message: 'Second threshold must be less than the first threshold!'
                });
                return;
            } else if (property === 'quantity_threshold' && value <= configs[index].secondary_quantity_threshold) {
                notification['error']({
                    message: 'First threshold must be greater than the second threshold!'
                });
                return;
            }
        }

        newData[index] = { ...newData[index], [property]: value };
    
        setConfigs(newData);
    
        const updatedConfig = { _id: newData[index]._id, [property]: value };
    
        setChangedConfigs(prevChangedConfigs => {
            const existingConfigIndex = prevChangedConfigs.findIndex(c => c._id === updatedConfig._id);
            if (existingConfigIndex > -1) {
                const updatedChangedConfigs = [...prevChangedConfigs];
                updatedChangedConfigs[existingConfigIndex] = { ...updatedChangedConfigs[existingConfigIndex], ...updatedConfig };
                return updatedChangedConfigs;
            } else {
                return [...prevChangedConfigs, updatedConfig];
            }
        });
    };
    
    
    
    const handleSave = async () => {
        setIsSaving(true);
        updateVendorsForSupplierAuthority({ changedVendors, changedConfigs }).then((response) => {
            notification['success']({
                message: 'Supplier Authorities successfully updated!'
            });
        }).catch((error) => {
            notification['error']({
                message: 'Error updating Supplier Authorities!'
            });
        }).finally(() => {
            handleReset();
            setIsSaving(false);
        })
    };
      
    const handleReset = async () => {
        dispatch(fetchVendorsForSupplierAuthority());
        dispatch(fetchSupplierAuthorityConfigs());
        setChangedConfigs([]);
        setChangedVendors([]);
    }

    return (
        <div className='supplier-authority-main-container'>
            <div className='supplier-authority-header'>
                <h2>{t('home.Supplier Authority')}</h2>
                <Space>
                    <Button type='primary' onClick={handleSave} disabled={isSaving} loading={isSaving}>
                        Save
                    </Button>
                    <Button type='secondary' onClick={handleReset} disabled={isSaving} loading={isSaving}>
                        Reset
                    </Button>
                </Space>
            </div>

            <div className='supplier-authority-configuration-container'>
                {
                    configs?.map((config, index) => {
                        return (
                            <Collapse key={index}>
                                <Panel header={`${config?.name} Configuration`} key={index}>
                                    <Form layout='vertical'>
                                        <Row gutter={16}>
                                            {config?.key !== 'review' && (
                                                <Col span={12}>
                                                <Form.Item className='supplier-authority-configuration-input-container' label='Number of Months to Take into Account'>
                                                    <InputNumber
                                                    className='supplier-authority-configuration-input'
                                                    step={1}
                                                    max={100}
                                                    min={0}
                                                    placeholder='Enter number of months'
                                                    value={config?.last_months}
                                                    onChange={(value) => handleConfigChange('last_months', value, index)}
                                                    />
                                                </Form.Item>
                                                </Col>
                                            )}
                                            <Col span={12}>
                                                <Form.Item className='supplier-authority-configuration-input-container' label={`${config?.key !== 'review' ? 'Quantity Threshold (%)' : 'First Threshold'}`}>
                                                <InputNumber
                                                    className='supplier-authority-configuration-input'
                                                    step={0.1}
                                                    max={100.0}
                                                    min={0.0}
                                                    placeholder='Enter quantity threshold'
                                                    value={config?.quantity_threshold}
                                                    onChange={(value) => handleConfigChange('quantity_threshold', value, index)}
                                                />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item className='supplier-authority-configuration-input-container' label='Second Threshold'>
                                                    <InputNumber
                                                    className='supplier-authority-configuration-input'
                                                    step={0.1}
                                                    max={100.0}
                                                    min={0.0}
                                                    placeholder='Enter second quantity threshold'
                                                    value={config?.secondary_quantity_threshold}
                                                    onChange={(value) => handleConfigChange('secondary_quantity_threshold', value, index)}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item className='supplier-authority-configuration-input-container' label='Third Threshold'>
                                                    <InputNumber
                                                    className='supplier-authority-configuration-input'
                                                    step={0.1}
                                                    max={100.0}
                                                    min={0.0}
                                                    placeholder='Enter third quantity threshold'
                                                    value={config?.tertiary_quantity_threshold}
                                                    onChange={(value) => handleConfigChange('tertiary_quantity_threshold', value, index)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            
                                            <Col span={12}>
                                                <Form.Item className='supplier-authority-configuration-input-container' label={config?.key === "refund" ? `Minimum Number of Unique Customers` : `Minimum Number of ${config?.name}s`}>
                                                <InputNumber
                                                    className='supplier-authority-configuration-input'
                                                    step={1}
                                                    max={100}
                                                    min={0}
                                                    placeholder='Enter minimum number'
                                                    value={config?.min_value}
                                                    onChange={(value) => handleConfigChange('min_value', value, index)}
                                                />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item className='supplier-authority-configuration-input-container' label={'First Punishment'}>
                                                <InputNumber
                                                    className='supplier-authority-configuration-input'
                                                    step={1}
                                                    max={3}
                                                    min={0}
                                                    placeholder='Enter punishment'
                                                    value={config?.punishment}
                                                    disabled
                                                />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item className='supplier-authority-configuration-input-container' label={'Second Punishment'}>
                                                <InputNumber
                                                    className='supplier-authority-configuration-input'
                                                    step={1}
                                                    max={3}
                                                    min={0}
                                                    placeholder='Enter punishment'
                                                    value={config?.secondary_punishment}
                                                    disabled
                                                />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item className='supplier-authority-configuration-input-container' label={'Third Punishment'}>
                                                <InputNumber
                                                    className='supplier-authority-configuration-input'
                                                    step={1}
                                                    max={3}
                                                    min={0}
                                                    placeholder='Enter punishment'
                                                    value={config?.tertiary_punishment}
                                                    disabled
                                                />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Panel>
                            </Collapse>
                        )
                    })
                }
            </div>
            
            <Table columns={SUPPLIER_AUTHORITY_COLUMN_NAMES} dataSource={vendors} pagination={{pageSize: 50}} sticky bordered/>

            
        </div>
    )
}

export default SupplierAuthority;